@use "settings" as *;
@use "sass:math";

.dark-mode-toggle {
	$svg-size: 32px;
	$border-width: 3px;

	position: fixed;
	top: math.div($spacing-unit, 2);
	right: math.div($spacing-unit, 2);
	margin: 0;
	padding: 0;
	outline: none;
	cursor: pointer;

	height: $svg-size + 2 * $border-width;
	width: $svg-size + 2 * $border-width;

	border-radius: 50%;
	background: var(--gray-2);
	border: $border-width solid var(--gray-2);
	transition: $dark-mode-transition-time $dark-mode-transition-func;

	&:hover, &:focus {
		border-color: #f7941d;
		@include dark-mode {
			border-color: #384ea2;
		}
	}

	.dark-mode-svg {
		$hide-scale: 0.5;
		$hide-translate: 20px;

		margin: 0;
		padding: 0;

		width: $svg-size;
		height: $svg-size;

		.icon-moon, .icon-sun, path {
			transition: $dark-mode-transition-time $dark-mode-transition-func;
		}

		path {
			fill: var(--bg-color);
		}

		&:hover {
			.icon-moon path {
				fill: var(--gray-1);
			}
			@include dark-mode {
				.icon-sun path {
					fill: var(--gray-1);
				}
			}
		}

		.icon-moon {
			opacity: 0;

			path {
				transform: scale($hide-scale) translateX(-$hide-translate);
				transform-origin: 64px 64px;
			}

			@include dark-mode {
				opacity: 1;
				path {
					transform: none;
				}
			}
		}

		.icon-sun {
			opacity: 1;

			path {
				transform: none;
				transform-origin: 192px 192px;
			}

			@include dark-mode {
				opacity: 0;

				path {
					transform: scale($hide-scale) translateX($hide-translate);
				}
			}
		}
	}
}
@use "settings" as *;
@use "sass:math";

/**
* Reset some basic elements
*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
    margin: 0;
    padding: 0;
}

*,
 ::before,
 ::after {
    box-sizing: border-box;
}


/**
* Basic styling
*/

body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: var(--text-color);
    background: var(--bg-color);
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    transition: $dark-mode-transition-time $dark-mode-transition-func;
}


/**
* Set `margin-bottom` to maintain vertical rhythm
*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure {
    margin-bottom: math.div($spacing-unit, 2);
}


/**
* Images
*/

img,
video {
    max-width: 100%;
    vertical-align: middle;
}

$figure-background: var(--gray-1);
figure {
    text-align: center;
}

figure img,
figure video {
    display: block;
    // box-sizing: border-box;
    margin: 0 auto;
    border-radius: 3px;
    background: $figure-background;
    padding: 10px;
}

figcaption {
    padding: 5px;
    font-size: 1em;
    font-style: italic;
}


/**
* Lists
*/

ul,
ol {
    margin-left: $spacing-unit;
}

li {
    >ul,
    >ol {
        margin-bottom: 0;
    }
}

summary {
    cursor: pointer;
}


/**
* Headings
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $base-font-weight;
}


/**
* Links
*/

a {
    color: var(--link-color);
    text-decoration: none;
    &:visited {
        color: var(--visited-link-color);
    }
    // &:hover {
    // color: var(--text-color);
    // text-decoration: underline;
    // }
}


/**
* Keyboard input element
*/

kbd {
    padding: 1px 2px;
    border-radius: 4px;
    font-size: .9em;
    font-variant: small-caps;
    letter-spacing: 0.8px;
    background: var(--gray-1);
    box-shadow: 1px 1px 1px var(--gray-4);
    transition: $dark-mode-transition-time $dark-mode-transition-func;
}


/**
* Block quotes
*/

blockquote {
    color: var(--gray-4);
    border-left: 4px solid var(--gray-4);
    padding-left: math.div($spacing-unit, 2);
    @include relative-font-size(1.125);
    letter-spacing: -1px;
    font-style: italic;
    > :last-child {
        margin-bottom: 0;
    }
}

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #eee;
    margin-bottom: 5px;
    vertical-align: middle;
    box-sizing: content-box;
}


/**
* Tables
*/

table {
    margin-bottom: $spacing-unit;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--gray-2);
    tr {
        &:nth-child(even) {
            background: var(--gray-1);
        }
    }
    th,
    td {
        padding: math.div($spacing-unit, 3) math.div($spacing-unit, 2);
    }
    th {
        background: var(--gray-2);
        border: 1px solid var(--gray-3);
    }
    td {
        border: 1px solid var(--gray-2);
    }
}

.clearfix {
    clear: both;
}
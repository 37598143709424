@use "settings" as *;
@use "sass:math";

.post-wrapper, .post-collection-wrapper {
	@include center-things();
}

.post-wrapper {
	width: 100%;
	margin: $spacing-unit * 2 0;
}

.post-collection-wrapper {
	margin: $spacing-unit 0;
}

.post, .article-excerpt {
	overflow-wrap: anywhere;

	width: 100%;
	padding: $spacing-unit;
	background: var(--bg-post);
	border-radius: 2px;

	transition: $dark-mode-transition-time background $dark-mode-transition-func;

	@include media-larger-than($post-width) {
		width: $post-width;

		padding: $spacing-unit * 2;

		// .post-header {
		// 	margin-bottom: $spacing-unit * 2;
		// }
	}
}

@include media-larger-than($post-width) {
	.post {
		padding: $spacing-unit * 3;
	}
}

.post, .article-excerpt, .nav-toc, .site-header, .site-footer {
	box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.09);
}

.article-excerpt {
	margin: $spacing-unit 0;

	@include media-larger-than($post-width) {
		margin-left: $spacing-unit;
		margin-right: $spacing-unit;
	}

	.expand-excerpt-link {
		float: right;
		border-radius: 1px;
		background: var(--gray-1);
		padding: 4px 24px 4px 8px;
		transition: 0.15s ease-out;

		@include clip-path(polygon(
			85% 0%,
			100% 50%,
			85% 100%,
			0% 100%,
			0 50%,
			0% 0%
		));


		&::before {
			display: none;
		}

		&:hover {
			background: var(--link-color);
			color: #fff;
			padding-left: 20px;

			@include clip-path(polygon(
				86% 0%,
				100% 50%,
				86% 100%,
				0% 100%,
				10% 50%,
				0% 0%
			));

			&:visited {
				background: var(--visited-link-color);
			}
		}
	}
}

.post-header {
	display: flex;
	margin-bottom: $spacing-unit;

	.teaser-image-container {
		@include center-things(column);
	}

	.post-header-col-headline {
		padding-left: $spacing-unit;

		@include center-things(column);
	}

	.post-title {
		margin: 0;
		font-size: 2em;
		font-family: $header-font-family;
		font-weight: 600;
		line-height: 1.5;

		a {
			background: linear-gradient(to right, $header-bg-color1, $header-bg-color2, $header-bg-color3, $header-bg-color4, $header-bg-color5, $header-bg-color6);
			background-size: 400% 400%;
			background-position: 0% 50%;
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;

			transition: 0.5s;

			&:hover {
				background-position: 100% 50%;
			}
		}

	}

	.teaser-image {
		$teaser-size: 80px;
		$cut-corner-size: 20%;

		@include clip-path(polygon(
			$cut-corner-size 0,
			100% 0,
			100% (100%-$cut-corner-size),
			(100%-$cut-corner-size) 100%,
			0 100%,
			0 $cut-corner-size
		));

		border-radius: 2px;
		width: $teaser-size;
		min-width: $teaser-size;
		height: $teaser-size;
	}
}

.dt-published::before {
	content: "// ";
	font-weight: bold;
}

.post-content a, .post-tags a {
	position: relative;
	text-decoration: none;

	&::before {
		content: "";
		position: absolute;
		bottom: 1px;
		left: 50%;
		width: 0%;
		height: 2px;

		background: var(--link-color);
		opacity: 0.1;
		transition: 0.2s;
	}
	&:visited::before {
		background: var(--visited-link-color);
	}

	&:hover {
		text-decoration: none;
		&::before {
			left: 0;
			opacity: 1;
			width: 100%;
		}
	}
}

.heading-link {
	$heading-link-width: 60px;

	.heading-link-icon {
		$height: 40px;
		$slope: 30%;

		display: none;
		vertical-align: middle;
		position: absolute;
		left: -$heading-link-width;
		top: 0;

		background: var(--bg-post);
		transition: $dark-mode-transition-time background $dark-mode-transition-func;

		// $slope: 30%;
		// @include clip-path(polygon(
		// 	$slope 0%,
		// 	100% 0%,
		// 	100% 100%,
		// 	$slope 100%,
		// 	0% 50%
		// ));

		padding: 8px;
		width: $heading-link-width;
		height: $height;

		@include media-larger-than($post-width) {
			display: inline-block;
		}

		path {
			fill: var(--gray-3);
			transition: 0.3s ease-out;
		}
	}

	a:hover .heading-link-icon path {
		fill: var(--link-color);
	}
}

.post-content {
	h1 {
		margin-top: $spacing-unit * 2;
	}

	h2, h3, h4, h5 {
		margin-top: $spacing-unit;
	}
}

.hyvor-talk-load-container {
	@include center-things();

	$corner-size: 12%;
	$corner-size-hover: 10%;

	#hyvor-talk-load {
		padding: $spacing-unit * 0.5 	$spacing-unit * 1.5;
		font: inherit;
		background: var(--gray-2);
		color: var(--text-color);
		outline: 0;
		border: 0;
		cursor: pointer;
		@include clip-path(polygon($corner-size 0%, 100% 0%, (100%-$corner-size) 100%, 0% 100%));
		transition: $dark-mode-transition-time $dark-mode-transition-func;

		&:hover, &:active, &:focus {
			background: var(--link-color);
			padding-left: $spacing-unit * 2 - 10px;
			padding-right: $spacing-unit * 2 - 10px;
			color: #fff;
			@include clip-path(polygon($corner-size-hover 0%, 100% 0%, (100%-$corner-size-hover) 100%, 0% 100%));
		}
	}
}

#hyvor-talk-view {
	margin-top: $spacing-unit * 2;
}

.katex-display {
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}

.table-container {
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;

	.table-container-inner {
		margin: 0 auto;
	}

	@include dark-mode {
		&::-webkit-scrollbar {
			background: var(--bg-post);
		}

		&::-webkit-scrollbar-thumb {
			border-color: var(--bg-post);
		}
	}
}

.excerpt-content {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		display: none;
	}
}
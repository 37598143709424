@use "settings" as *;
@use "sass:math";

$border-width: 4px;

.nav-toc {
	position: -webkit-sticky;
	position: sticky;
	top: $spacing-unit * 2;
	right: 0;

	width: $toc-width;
	height: max-content;
	max-height: calc(100vh - #{$spacing-unit * 4});
	overflow-y: auto;

	background: var(--bg-post);

	@include media-larger-than($post-width + $toc-width) {
		margin-left: $spacing-unit * 2;
		box-shadow: none;
		background: transparent;

		&.nav-toc > strong {
			padding-top: 0;
			padding-left: $spacing-unit * 1.5 + $border-width;
			background: transparent;
			text-align: left;
		}

		.toc {
			padding-bottom: 0;
		}

		.toc li.active {
			border-color: var(--link-color);
		}
	}

	@include media-smaller-than($post-width + $toc-width + $spacing-unit * 2) {
		margin-top: $spacing-unit * 2;
		max-height: max-content;
	}

	a {
		color: var(--text-color);
		transition: 0.1s ease-out;

		&:hover {
			color: var(--link-color);
		}
	}

	> strong {
		display: block;
		background: var(--gray-1);
		padding: 16px;
		font: bold 1.2em $header-font-family;
		text-align: center;
	}

	.toc {
		padding: math.div($spacing-unit, 2) $spacing-unit;

		ol {
			list-style: none;
			margin-left: 0;
			padding-left: 0;
		}

		li {
			padding-left: math.div($spacing-unit, 2);
			margin: math.div($spacing-unit, 2) 0;
			border-left: $border-width solid var(--gray-3);
			transition: $dark-mode-transition-time;
		}
	}
}

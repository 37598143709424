@use "settings" as *;

$lineHeight: 1.5em;
$tabSize: 4;

code,
pre {
	font-family: "Consolas", monospace;
	font-size: 0.9em;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: $lineHeight;

	-moz-tab-size: $tabSize;
	-o-tab-size: $tabSize;
	tab-size: $tabSize;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;

	transition: $dark-mode-transition-time background $dark-mode-transition-func, $dark-mode-transition-time border-color $dark-mode-transition-func;
}

code {
	overflow: auto;
	border-radius: 2px;
	background: var(--gray-1);
	padding: 2px 3px;
}

pre code {
	background: none;
	padding-left: 0;
	padding-right: 0;
}

pre {
	padding: 0.6em 0.8em;
	border-left: 4px solid var(--gray-3);
	border-radius: 3px;
	overflow-x: auto;
	background: linear-gradient(150deg, #fafafa, #efefef);

	@include dark-mode {
		background: linear-gradient(150deg, #282c34, #20242b);
	}
}

.hljs {
	overflow-x: auto;
	display: block;
	color: #333;

	@include dark-mode {
		scrollbar-color: #aaa #20242b;

		&::-webkit-scrollbar {
			background: #20242b;
		}

		&::-webkit-scrollbar-thumb {
			border-color: #20242b;
		}
	}

}

// Light theme uses highlight.js github-gist theme
// Dark theme uses atom-one-dark theme

.hljs-comment,
.hljs-meta {
	color: #1aa739;
}

.hljs-comment {
	font-style: italic;
}

.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
	color: #df5000;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
	color: #d73a49;
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
	color: #0086b3;
}

.hljs-section,
.hljs-name {
	color: #63a35c;
}

.hljs-tag {
	color: #333333;
}

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
	color: #6f42c1;
}

.hljs-addition {
	color: #55a532;
	background: #eaffea;
}

.hljs-deletion {
	color: #bd2c00;
	background: #ffecec;
}

.hljs-link {
	text-decoration: underline;
}

.hljs-number {
	color: #005cc5;
}

.hljs-string {
	color: #032f62;
}

@include dark-mode {
	.hljs {
		color: #abb2bf;
	}

	.hljs-comment,
	.hljs-quote {
		color: #5c6370;
		font-style: italic;
	}

	.hljs-doctag,
	.hljs-keyword,
	.hljs-formula {
		color: #c678dd;
	}

	.hljs-section,
	.hljs-name,
	.hljs-selector-tag,
	.hljs-deletion,
	.hljs-subst {
		color: #e06c75;
	}

	.hljs-literal {
		color: #56b6c2;
	}

	.hljs-string,
	.hljs-regexp,
	.hljs-addition,
	.hljs-attribute,
	.hljs-meta-string {
		color: #98c379;
	}

	.hljs-built_in,
	.hljs-class .hljs-title {
		color: #e6c07b;
	}

	.hljs-attr,
	.hljs-variable,
	.hljs-template-variable,
	.hljs-type,
	.hljs-selector-class,
	.hljs-selector-attr,
	.hljs-selector-pseudo,
	.hljs-number {
		color: #d19a66;
	}

	.hljs-symbol,
	.hljs-bullet,
	.hljs-link,
	.hljs-meta,
	.hljs-selector-id,
	.hljs-title {
		color: #61aeee;
	}

	.hljs-emphasis {
		font-style: italic;
	}

	.hljs-strong {
		font-weight: bold;
	}
}

@use "settings" as *;

$breakpoint-medium: 450px; // minimum width for medium (2 rows)
$breakpoint-large: 900px; // minimum width for large (1 row)

.site-header-nav {
	display: grid;
	grid-template-columns: auto;

	grid-column-gap: $spacing-unit * 2;
	column-gap: $spacing-unit * 2;
	grid-row-gap: $spacing-unit;
	row-gap: $spacing-unit;

	justify-content: center;
	margin-bottom: $spacing-unit * 2;

	@include media-larger-than($breakpoint-medium) {
		grid-template-columns: auto auto;
		grid-row-gap: $spacing-unit * 2;
		row-gap: $spacing-unit * 2;
	}

	@include media-larger-than($breakpoint-large) {
		grid-template-columns: auto auto 10px auto auto;
	}

	@include media-smaller-than($breakpoint-medium) {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: height 0.3s ease-out, opacity 0.2s ease-out;

		&.visible {
			height: 280px;
			opacity: 1;
			transition: height 0.3s ease-out, opacity 0.1s 0.2s ease-out;
		}
	}
}

// rhombus divider
.site-header-nav .divider {
	display: none;

	@include no-select;
	cursor: default;

	color: rgba(255, 255, 255, 0.2);
	transform: translateY(5px);
	transition: 0.5s;

	@include media-larger-than($breakpoint-large) {
		display: inline-block;
	}

	&:hover {
		color: $brand-color;
	}
}

a.site-header-link {
	$corner-size: 10%;
	$corner-size-2: 35%;

	position: relative;
	z-index: 1;
	padding: 5px 20px;

	color: #eee;
	font-family: "Montserrat", sans-serif;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-decoration: none;

	@include media-smaller-than($breakpoint-large) {
		background: rgba(100, 100, 120, 0.2);
		transition: 0.2s ease-in-out;

		&::before {
			display: none;
		}

		&:hover {
			background: $brand-color;
		}
	}

	&::before {
		content: attr(data-hover);

		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		padding: 5px 20px;

		background: $brand-color;
		color: rgba(0, 0, 0, 0);

		@include clip-path(
			polygon((100%-$corner-size-2) 0, (100%-$corner-size-2) 0, $corner-size-2 100%, $corner-size-2 100%)
		);

		transition: 0.2s ease-out;
	}

	&:hover::before {
		@include clip-path(polygon($corner-size 0%, 100% 0%, (100%-$corner-size) 100%, 0% 100%));
	}

	// reverse the direction of the parallelogram on half the links
	&.backwards {
		&::before {
			@include clip-path(
				polygon($corner-size-2 0, $corner-size-2 0, (100%-$corner-size-2) 100%, (100%-$corner-size-2) 100%)
			);
		}

		&:hover::before {
			@include clip-path(polygon(0% 0%, (100%-$corner-size) 0%, 100% 100%, $corner-size 100%));
		}
	}

	// the triangle beneath the current page
	&.selected::after {
		content: "";

		position: absolute;
		bottom: -14px;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -7px;
		pointer-events: none; // prevent hover/nonhover thrashing

		border: 7px solid transparent;
		border-bottom: 10px solid #fff;
		transition: 0.2s;
	}

	&.selected:hover::after {
		// fly up and fade
		bottom: 20px;
		opacity: 0;
		border-bottom-color: $brand-color;
	}
}

.site-header-nav-icon {
	$icon-color: #eee;
	$line-width: 50px;
	$line-height: 6px;
	$line-spacing: 12px;

	display: none;
	cursor: pointer;
	border: 0;
	outline: 0;
	padding: 0;
	background: transparent;
	transition: margin-bottom 0.1s 0.2s;

	@include media-smaller-than($breakpoint-medium) {
		display: inline-block;
	}

	&:hover .hamburger-inner {
		background: #3d7bf2;
		&::before,
		&::after {
			background-position: 100% 50%;
		}
	}

	&.open {
		margin-bottom: $spacing-unit * 2;
		transition: margin-bottom 0.2s;
	}

	.hamburger-box {
		width: $line-width;
		height: $line-height * 3 + $line-spacing * 2;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner {
		display: block;
		top: 50%;
		background: $icon-color;
		transition: background 0.1s 0s ease-out;

		&,
		&::before,
		&::after {
			position: absolute;
			width: $line-width;
			height: $line-height;
		}

		&::before {
			background: linear-gradient(to right, $icon-color, #aaa, #3d7bf2, #6d9cf5);
		}
		&::after {
			background: linear-gradient(to right, $icon-color, #aaa, #3d7bf2, #105bec);
		}

		&::before,
		&::after {
			content: "";
			display: block;
			background-size: 400% 400%;
			background-position: 0% 50%;
			transition: transform 0.1s ease-in, top 0.1s 0.15s ease-out, background-position 0.2s ease-out;
		}

		&::before {
			top: -($line-spacing + $line-height);
		}

		&::after {
			top: $line-spacing + $line-height;
		}
	}

	&.open .hamburger-inner {
		background: transparentize($color: #3d7bf2, $amount: 1);
		transition: background 0.1s ease-out;

		&::before,
		&::after {
			top: 0;
			transition: top 0.1s ease-in, transform 0.1s 0.15s ease-out, background-position 0.2s ease-out;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

@use "settings" as *;

.premonition {
	$note-color: #2a98b9;
	$note-light-color: #e3edf2;
	$info-color: #50af51;
	$info-light-color: #e8ffe8;
	$warning-color: #f0ad4e;
	$warning-light-color: #fdffe0;
	$error-color: #d9534f;
	$error-light-color: #fee0e0;

	border-left: 4px solid var(--gray-4);
	padding: 8px 12px;
	padding-bottom: 1px;
	margin-bottom: $spacing-unit;

	.premonition-header {
		font-weight: bold;
	}

	@mixin box-type($c, $lc) {
		border-color: $c;
		background: $lc;

		.premonition-header {
			color: $c;
		}
	}

	&.premonition-info {
		@include box-type($info-color, $info-light-color);
	}
	&.premonition-note {
		@include box-type($note-color, $note-light-color);
	}
	&.premonition-warning {
		@include box-type($warning-color, $warning-light-color);
	}
	&.premonition-error {
		@include box-type($error-color, $error-light-color);
	}
}

@include dark-mode {
	.premonition {
		background: linear-gradient(40deg, #2f2f2f, #1a1a1a);
	}
}

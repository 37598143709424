@use "settings" as *;

$footer-text-color: #d0d0d0;
$footer-highlight-color: $link-color-dark;

.site-footer {
	background: var(--bg-site-header);
	padding: $spacing-unit 0;
	color: $footer-text-color;

	@include center-things();

	.svg-icon {
		fill: $footer-text-color;
		opacity: 0.7;
		transition: 0.15s ease-out;
		padding-right: 8px;
		transform: translateY(1px);
	}

	a {
		color: inherit;
		text-decoration: none;
		transition: 0.15s ease-out;
		&:hover {
			color: $footer-highlight-color;

			.svg-icon {
				fill: $footer-highlight-color;
			}
		}
	}

	.contact-list {
		list-style: none;
		margin: 0;
	}
}

.site-footer-section {
	padding: $spacing-unit;

	@include media-larger-than(1000px) {
		padding: $spacing-unit $spacing-unit * 2;
	}
}

@use "settings" as *;

@mixin polygon($corner-size) {
	@include clip-path(
		polygon((100%-$corner-size) 0, 100% 50%, (100%-$corner-size) 100%, $corner-size 100%, 0 50%, $corner-size 0)
	);
}

@keyframes moveBackgroundImage {
	from {
		background-position: 100% 50%;
	}
	to {
		background-position: 0% 50%;
	}
}

.site-header {
	background: var(--bg-site-header);
	text-align: center;
}

.site-header-text {
	font-family: $header-font-family;
	text-transform: uppercase;
	margin: 0;
	padding: $spacing-unit * 2 0;

	a {
		display: inline-block;
		font-size: 12vw; // 2 rows
		font-weight: 600;
		letter-spacing: 3px;
		color: #eee;
		text-decoration: none;

		transition: 0.3s;

		&:hover, &:focus {
			color: var(--link-color);
		}

		@include media-larger-than(600px) {
			font-size: 6vw; // one row, responsive
		}

		@include media-larger-than(1200px) {
			font-size: 50px; // one row fixed
			padding: 8px 0;
			color: var(--bg-site-header);
			width: 900px;

			@include polygon(7%);

			background: linear-gradient(
				70deg,
				$header-bg-color4,
				$header-bg-color5,
				$header-bg-color6,
				$header-bg-color1,
				$header-bg-color2,
				$header-bg-color3
			);
			background-size: 400% 400%;
			background-position: 0% 50%;

			animation: moveBackgroundImage 1.4s ease-out;
			transition: 0.9s;

			&:hover, &:focus {
				@include polygon(4%);

				color: var(--bg-site-header);
				background-position: 100% 50%;
			}
		}
	}
}
